import React from 'react'
import { Typography, Button } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";

const TrachealCannula = () => {
  AOS.init({
    duration : 1500
  })

  return (
    <div style={styles.container} data-aos="fade-in">
      <Typography align='center' variant='h3' style={{ textTransform: 'uppercase' }}>Tracheal Stoma Stud</Typography>

      <div style={{ width: '50%', margin: '0 auto' }}>
        <p style={{ textAlign: 'left', fontWeight: 100 }}>Tracheostomy is a surgically created hole (stoma) in the trachea that provides an alternative airway for breathing when such intervention is needed. The air needs to be filtered before it enters the body so a specific filter needs to be attached. This filter needs to be placed in a stoma stud that ensure the entry to the trachea.</p>
        <p style={{ textAlign: 'left', fontWeight: 100  }}>Currently, the stoma studs are most commonly offered only in 3 sizes. In some cases, none of these options provides a comfortable fit. Moreover,  it is now truly necessary to apply the stickers to ensure the positioning. The goal of this project was to investigate the design and production of unique, patient-specific studs that have optimal fit and do not require stickers.</p>

        <p style={{ textAlign: 'left', fontWeight: 100, fontSize: 12 }}>To stay on the same slide please hover the mouse over it.</p>
      </div>

      <div style={styles.linkContainer}>
        <div style={{ width: '50%', margin: ' 0 auto'}}>
          <Carousel autoPlay stopOnHover infiniteLoop showIndicators={false} interval={10000}>
            <div>
              <img src={require('../../../assets/tracheostoma/stoma1.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/tracheostoma/stoma2.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/tracheostoma/stoma3.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/tracheostoma/stoma4.png')} alt='pic' />
            </div>
            <div>
              <video loop autoPlay width='100%' style={{ marginTop: 85 }}>
                <source src={require('../../../assets/tracheostoma/video.mp4')} type='video/mp4' />
              </video>
            </div>
            <div>
              <img src={require('../../../assets/tracheostoma/stoma5.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/tracheostoma/stoma6.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/tracheostoma/stoma7.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/tracheostoma/stoma8.png')} alt='pic' />
            </div>
          </Carousel>
        </div>
      </div>

      <div style={{ textAlign: "left", width: '50%', margin: '0 auto', marginBottom: 35 }}>
        <p style={{margin: '0', padddin: '0' ,textAlign: "left", fontWeight: 100}}>Year: 2019</p> 
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Companies: Saxion, Medisch Spectrum Twente</p>
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Collaboration with: Esther de Roller</p>
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Design software: Rhino3D + Grasshopper + Fusion 360</p>
      </div>

      <div className="navbutton" style={{ display: "flex", justifyContent: 'space-evenly' }}>
        <Link to='/portfolio/welding-pushers' style={styles.link}>
          <Button 
            startIcon={<ArrowBackIosIcon/>}
            style={styles.button}>Previous Project</Button>
        </Link>
        <Link to='/' style={styles.link}>
          <Button style={styles.button}>Back to portfolio</Button>
        </Link>
        <Link to='/portfolio/parametric-art' style={styles.link}>
          <Button
            endIcon={<ArrowForwardIosIcon/>}
            style={styles.button}>Next Project</Button>
        </Link>
      </div>
    </div>
  )
}

const styles = {
  container: {
    marginTop: 25, 
    color: '#fff',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    color: '#fff'
  }, 
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '25px'
  }
}

export default TrachealCannula
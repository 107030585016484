/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Typography, Button } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";

const Miscellaneous = () => {
  AOS.init({
    duration : 1500
  })

  return (
    <div style={styles.container} data-aos="fade-in">
      <Typography
        align="center"
        variant="h3"
        style={{ textTransform: "uppercase" }}
      >
        Miscellaneous
      </Typography>

      <div
        style={{
          width: "75%",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          marginTop: "35px",
          borderBottom: "1px solid #ddd",
          paddingBottom: 45,
        }}
      >
        <div style={{ width: "650px", marginRight: 25 }}>
          <Typography
            style={{ textAlign: "right", fontWeight: 100 }}
            variant="h5"
          >
            The UNited gift shop
          </Typography>
          <p style={{ textAlign: "right", fontWeight: 100 }}>
            The United Nations Visiting Center in New York collaborated with
            Saxion University of Applied Sciences on a project aiming to
            initiate a new product line for their on-site gift shop. The brief
            stated that they are looking for a design that can be printed in
            maximum 3 colors on all kinds of surfaces, including textiles.
            Concepts outside this range were welcome but without certainty that
            could be produced and offered in the shop.
          </p>
          <p style={{ textAlign: "right", fontWeight: 100 }}>
            My starting point was the idea that we as people, have more things
            in common than different. We eat the same way, we do sports, we
            study and we play. From there I came up with the title “UNited we …”
            and the symbol of a spiral stair evolving upwards. The main concept
            had the potential to be translated into several sub-product lines,
            such as “UNited we cook” and offer kitchen equipment and spices,
            “UNited we are learn” could include school supplies and “UNited we
            play” was intended for cards and games such as chess. I then created
            a chess set with design intended to re-create the 2D logo design to
            3D game pieces.
          </p>
          <a
            style={{ textDecoration: "none", color: "#fff" }}
            href="https://ungiftshop.wordpress.com/"
            target="_blank"
          >
            <p>Read more here</p>
          </a>
        </div>

        <div>
          <img
            style={{ width: "450px" }}
            src={require("../../../assets/miscellaneous/giftshot/UNgiftshop.jpg")}
            alt="ungift"
          />
        </div>
      </div>

      <div
        style={{
          width: "75%",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
          borderBottom: "1px solid #ddd",
          paddingBottom: 45,
        }}
      >
        <div>
          <img
            style={{ width: "450px" }}
            src={require("../../../assets/miscellaneous/monster/monstersProject.jpg")}
            alt="Monster"
          />
        </div>
        <div style={{ width: "650px", marginLeft: 25 }}>
          <Typography
            style={{ textAlign: "left", fontWeight: 100 }}
            variant="h5"
          >
            The Monster Project
          </Typography>
          <p style={{ textAlign: "left", fontWeight: 100 }}>
            The Monsters project aims to inspire youngsters to pursue career in
            the creative industries. In short, elementary students draw monsters
            then artists from all over the world reimagine those monsters in
            their own unique styles.
          </p>
          <p style={{ textAlign: "left", fontWeight: 100 }}>
            Me and my fellow students from Art & Technology at Saxion University
            of Applied Sciences, were invited to take part of this project using
            the drawings of children from a school in the city.
          </p>
          <p style={{ textAlign: "left", fontWeight: 100 }}>
            I wanted to re-make the monster into both a digital and physical
            model and in the process I discovered different ways the digital
            modeling complemented the physical realization and vice-versa. You
            are welcome to read more about the design and realization steps
            here:
          </p>
          <a
            style={{ textDecoration: "none", color: "#fff" }}
            href="https://monstersforsaxion.wordpress.com/"
            target="_blank"
          >
            <p>Read more here</p>
          </a>
        </div>
      </div>

      <div
        style={{
          width: "75%",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
          borderBottom: "1px solid #ddd",
          paddingBottom: 45,
        }}
      >
        <div style={{ width: "650px", marginRight: 25 }}>
          <Typography
            style={{ textAlign: "right", fontWeight: 100 }}
            variant="h5"
          >
            Solar Seating
          </Typography>
          <p style={{ textAlign: "right", fontWeight: 100 }}>
            During my minor of Sustainability in Product design and
            advertisement I developed the concept for solar powered beach
            furniture.
          </p>
          <p style={{ textAlign: "right", fontWeight: 100 }}>
            The item developed was a single person seating with solar strips
            attached to the top.
          </p>
          <p style={{ textAlign: "right", fontWeight: 100 }}>
            The idea was that the seating provides shading during the day white
            the solar strips charge and one could use it for lighting at night.
            The design is yet at conceptual phase.
          </p>
        </div>

        <div>
          <img
            style={{ width: "450px" }}
            src={require("../../../assets/miscellaneous/solarSeating/beachFurnitureSeating1.jpg")}
            alt="Beacht forniture"
          />
        </div>
      </div>

      <div
        style={{
          width: "57%",
          margin: "0 auto",
          display: "flex",
          marginTop: "50px",
        }}
      >
        <div>
          <Typography
            style={{ textAlign: "left", fontWeight: 100 }}
            variant="h5"
          >
            Visual & Concepts
          </Typography>

          <div style={{ width: "75%", margin: "0 auto", marginTop: 25 }}>
            <Carousel
              autoPlay
              stopOnHover
              infiniteLoop
              showIndicators={false}
              interval={10000}
            >
              <div style={{ marginTop: 120 }}>
                <img
                  src={require("../../../assets/miscellaneous/galleryVisuals/loftTest.png")}
                  alt="pic"
                />
              </div>
              <div style={{ marginTop: 175 }}>
                <img
                  src={require("../../../assets/miscellaneous/galleryVisuals/lampRenderTexture.png")}
                  alt="pic"
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <img
                  src={require("../../../assets/miscellaneous/galleryVisuals/glassPractice.png")}
                  alt="pic"
                />
              </div>
              <div style={{ marginTop: 130 }}>
                <img
                  src={require("../../../assets/miscellaneous/galleryVisuals/clockTopSide.png")}
                  alt="pic"
                />
              </div>
              <div style={{ marginTop: 130 }}>
                <img
                  src={require("../../../assets/miscellaneous/galleryVisuals/clockFrontSide.png")}
                  alt="pic"
                />
              </div>
              <div>
                <img
                  src={require("../../../assets/miscellaneous/galleryVisuals/chamileoTexture.JPG")}
                  alt="pic"
                />
              </div>
            </Carousel>
          </div>
        </div>
      </div>

      <div
        className="navbutton"
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <Link to="/portfolio/parametric-art" style={styles.link}>
          <Button startIcon={<ArrowBackIosIcon />} style={styles.button}>
            Prev Project
          </Button>
        </Link>
        <Link to="/" style={styles.link}>
          <Button endIcon={<ArrowForwardIosIcon />} style={styles.button}>
            Back to Portfolio
          </Button>
        </Link>
      </div>
    </div>
  );
}

const styles = {
  container: {
    marginTop: 25,
    color: '#fff',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    color: '#fff'
  }, 
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '25px'
  }
}

export default Miscellaneous

import React from 'react'
import { Typography, Button } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";

const ParametricVase = () => {
  AOS.init({
    duration : 1500
  })

  return (
    <div style={styles.container} data-aos="fade-in">
      <Typography align='center' variant='h3' style={{ textTransform: 'uppercase' }}>Parametric Vases</Typography>

      <div style={{ width: '50%', margin: '0 auto' }}>
        <p style={{ textAlign: 'left', fontWeight: 100 }}>Re-make Plastics is an initiative dedicated to the development of the technology and research into applications of large scale 3D printing of  recycled plastic.</p>
        <p style={{ textAlign: 'left', fontWeight: 100  }}>I participated in this project as a designer of the final products. We wanted to create a variety of forms that demonstrate the technology’s abilities and the choice was made for parametric vases.</p>

        <p style={{ textAlign: 'left', fontWeight: 100, fontSize: 12 }}>To stay on the same slide please hover the mouse over it.</p>
      </div>

      <div style={styles.linkContainer}>
        <div style={{ width: '50%', margin: ' 0 auto'}}>
          <Carousel autoPlay stopOnHover infiniteLoop showIndicators={false} interval={10000}>
            <div>
              <img src={require('../../../assets/parametricVases/parametric-1.png')} alt='pic' />
            </div>
            <div>
              <video loop autoPlay width='100%' style={{ marginTop: 75 }}>
                <source src={require('../../../assets/parametricVases/video.mp4')} type='video/mp4' />
              </video>
            </div>
            <div>
              <img src={require('../../../assets/parametricVases/parametric-2.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/parametricVases/parametric-3.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/parametricVases/parametric-4.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/parametricVases/parametric-5.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/parametricVases/parametric-6.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/parametricVases/parametric-7.png')} alt='pic' />
            </div>
          </Carousel>
        </div>
      </div>

      <div style={{ textAlign: "left", width: '50%', margin: '0 auto', marginBottom: 35 }}>
        <p style={{margin: '0', padddin: '0' ,textAlign: "left", fontWeight: 100}}>Year: 2019</p> 
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Project coordinator: Volker Ruitinga</p>
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Production manager: Lars Kooijman</p>
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Design and slicing software: Rhino3D + Grasshopper + Kangaroo</p>
      </div>

      <div className="navbutton" style={{ display: "flex", justifyContent: 'space-evenly' }}>
        <Link to='/portfolio/facade-elements' style={styles.link}>
            <Button 
              startIcon={<ArrowBackIosIcon/>}
              style={styles.button}>Prev Project</Button>
          </Link>

        <Link to='/' style={styles.link}>
          <Button style={styles.button}>Back to portfolio</Button>
        </Link>
        
        <Link to='/portfolio/energy-neutral-shader' style={styles.link}>
          <Button
            endIcon={<ArrowForwardIosIcon/>}
            style={styles.button}>Next Project</Button>
        </Link>
      </div>

    </div>
  )
}

const styles = {
  container: {
    marginTop: 25, 
    color: '#fff',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    color: '#fff'
  }, 
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '25px'
  }
}

export default ParametricVase
import React from "react";
import { Typography, Button } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom";

const ParametricArt = () => {

  return (
    <div style={styles.container} data-aos="fade-in">
      <Typography
        align="center"
        variant="h3"
        style={{ textTransform: "uppercase" }}
      >
        Coming Soon
      </Typography>

      <div
        className="navbutton"
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <Link to="/portfolio/tracheal-stoma-stud" style={styles.link}>
          <Button startIcon={<ArrowBackIosIcon />} style={styles.button}>
            Previous Project
          </Button>
        </Link>
        <Link to="/" style={styles.link}>
          <Button style={styles.button}>Back to portfolio</Button>
        </Link>
        <Link to="/portfolio/miscellaneous" style={styles.link}>
          <Button endIcon={<ArrowForwardIosIcon />} style={styles.button}>
            Next Project
          </Button>
        </Link>
      </div>
    </div>
  );
};

const styles = {
  container: {
    marginTop: 25,
    color: "#fff",
    textAlign: "center",
  },
  link: {
    textDecoration: "none",
  },
  button: {
    color: "#fff",
  },
  linkContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "25px",
  },
};

export default ParametricArt;

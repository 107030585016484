import greenFacade from "../../../assets/images/thumbnail-1.jpg";
import lineSlicer from "../../../assets/images/thumbnail-10.jpg";
import miscellaneous from "../../../assets/images/thumbnail-11.jpg";
import dam from "../../../assets/images/thumbnail-12.jpg";
import sphere from "../../../assets/images/thumbnail-13.jpg";
import parametrciArt from "../../../assets/images/thumbnail-14.jpg";
import weldingPushers from "../../../assets/images/thumbnail-2.jpg";
import cannula from "../../../assets/images/thumbnail-3.jpg";
import greenDome from "../../../assets/images/thumbnail-4.jpg";
import geraniumCufflinks from "../../../assets/images/thumbnail-5.jpg";
import energyShader from "../../../assets/images/thumbnail-6.jpg";
import facadeNumbers from "../../../assets/images/thumbnail-7.jpg";
import parametricVases from "../../../assets/images/thumbnail-8.jpg";
import concreteTub from "../../../assets/images/thumbnail-9.jpg";

const portfolioData = [
  {
    id: 1,
    img: greenFacade,
    title: "Green Facade",
    author: "Iman Hadzhivalcheva",
  },
  {
    id: 2,
    img: weldingPushers,
    title: "Welding Pushers",
    author: "Iman Hadzhivalcheva",
  },
  {
    id: 3,
    img: cannula,
    title: "Tracheal Cannula",
    author: "Iman Hadzhivalcheva",
  },
  {
    id: 4,
    img: greenDome,
    title: "Green Dome",
    author: "Iman Hadzhivalcheva",
  },
  {
    id: 5,
    img: geraniumCufflinks,
    title: "Geranium Cufflinks",
    author: "Iman Hadzhivalcheva",
  },
  {
    id: 6,
    img: energyShader,
    title: "Energy Neutral Shader",
    author: "Iman Hadzhivalcheva",
  },
  {
    id: 7,
    img: facadeNumbers,
    title: "Facade Numbers",
    author: "Iman Hadzhivalcheva",
  },
  {
    id: 8,
    img: parametricVases,
    title: "Parametric Vases",
    author: "Iman Hadzhivalcheva",
  },
  {
    id: 9,
    img: concreteTub,
    title: "Concrete Hot Tub",
    author: "Iman Hadzhivalcheva",
  },
  {
    id: 10,
    img: lineSlicer,
    title: "Online Slicer",
    author: "Iman Hadzhivalcheva",
  },
  {
    id: 11,
    img: miscellaneous,
    title: "Miscellaneous",
    author: "Iman Hadzhivalcheva",
  },
  {
    id: 12,
    img: dam,
    title: "Dam",
    author: "Iman Hadzhivalcheva",
  },
  {
    id: 13,
    img: sphere,
    title: "Sphere",
    author: "Iman Hadzhivalcheva",
  },
  {
    id: 14,
    img: parametrciArt,
    title: "Parametric Art",
    author: "Iman Hadzhivalcheva",
  },
];

export default portfolioData;

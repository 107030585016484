import React from 'react'
import { Typography, Button } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Link } from 'react-router-dom'
import ReactPlayer from "react-player"

const GreenDome = () => {
  return (
    <div style={styles.container} data-aos="fade-in">
      <Typography align='center' variant='h3' style={{ textTransform: 'uppercase' }}>Green Dome</Typography>

      <div style={{ width: '50%', margin: '0 auto' }}>
        <p style={{ textAlign: 'left', fontWeight: 100 }}>The Green Dome is a concept for an outdoor housing of a composting unit to promote local recycling of green waste. It is the biggest and the heaviest 3D puzzle so far.</p>
        <p style={{ textAlign: 'left', fontWeight: 100  }}>The initial design of the Dome changed with the choice of composting machine so the user interaction can be easy and straight forward. All blocks were printed at the Concrete lab facilities at Saxion University of Applied Sciences. A sand-bed principle was used to support the extreme overhangs.</p>
      </div>

      <div style={{ width: '45%', margin: '0 auto', marginBottom: '20px' }}>
        <ReactPlayer
          width='100%'
          height='50vh'
          url="https://www.youtube.com/watch?v=Gn0mQOyB7nI"
        />
      </div>

      <div style={{ textAlign: "left", width: '50%', margin: '0 auto', marginBottom: 35 }}>
        <p style={{margin: '0', padddin: '0' ,textAlign: "left", fontWeight: 100}}>Year: 2019-2020</p> 
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Company: Saxion, Twente Milieu, TWW</p>
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Design software: Rhino + Grasshopper + Fusin 360</p>
      </div>

      <div className="navbutton" style={{ display: "flex", justifyContent: 'space-evenly' }}>
        <Link to='/portfolio/sphere' style={styles.link}>
          <Button 
            startIcon={<ArrowBackIosIcon/>}
            style={styles.button}>Previous Project</Button>
        </Link>
        <Link to='/' style={styles.link}>
          <Button style={styles.button}>Back to portfolio</Button>
        </Link>
        <Link to='/portfolio/geranium-cufflinks' style={styles.link}>
          <Button
            endIcon={<ArrowForwardIosIcon/>}
            style={styles.button}>Next Project</Button>
        </Link>
      </div>
    </div>
  )
}

const styles = {
  container: {
    marginTop: 25,
    color: '#fff',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    color: '#fff'
  }, 
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-around'
  }
}

export default GreenDome

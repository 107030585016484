import { Button, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AOS from "aos";
import "aos/dist/aos.css";
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from 'react-router-dom';

const FacadeNumbers = () => {
  AOS.init({
    duration : 1500
  })

  return (
    <div style={styles.container} data-aos="fade-in">
      <Typography align='center' variant='h3' style={{ textTransform: 'uppercase' }}>Facade Elements</Typography>

      <div style={{ width: '50%', margin: '0 auto' }}>
        <p style={{ textAlign: 'left', fontWeight: 100 }}>3D concrete printing allows a great design freedom, ideal for decorative elements. Such was the case with a newly built neighborhood in need for a house number panels.</p>
        <p style={{ textAlign: 'left', fontWeight: 100  }}>For this project, both Vertico and I, preferred the use of  parametric modeling as it allowed us to make variations of the design very quick and adjust to the preferences of the end client.</p>

        <p style={{ textAlign: 'left', fontWeight: 100, fontSize: 12 }}>To stay on the same slide please hover the mouse over it.</p>
      </div>

      <div style={styles.linkContainer}>
        <div style={{ width: '50%', margin: ' 0 auto'}}>
          <Carousel autoPlay stopOnHover infiniteLoop showIndicators={false} interval={10000}>
            <div>
              <img src={require('../../../assets/facadeNumbers/facadenr1.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/facadeNumbers/facadenr2.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/facadeNumbers/facadenr3.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/facadeNumbers/facadenr4.png')} alt='pic' />
            </div>
            <div>
              <video loop autoPlay width='100%' style={{ marginTop: 85 }}>
                <source src={require('../../../assets/facadeNumbers/video1.mp4')} type='video/mp4' />
              </video>
            </div>
            <div>
              <img src={require('../../../assets/facadeNumbers/facadenr5.png')} alt='pic' />
            </div>
            <div>
              <video loop autoPlay width='100%' style={{ marginTop: 85 }}>
                <source src={require('../../../assets/facadeNumbers/video2.mp4')} type='video/mp4' />
              </video>
            </div>
            <div>
              <img src={require('../../../assets/facadeNumbers/facadenr6.png')} alt='pic' />
            </div>
          </Carousel>
        </div>
      </div>

      <div style={{ textAlign: "left", width: '50%', margin: '0 auto', marginBottom: 35 }}>
        <p style={{margin: '0', padddin: '0' ,textAlign: "left", fontWeight: 100}}>Year: 2018</p> 
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Company: Vertico</p>
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Software: Revit+ Rhino3D + Grasshopper + Ladybug tools + Galapagos</p>
      </div>

      <div className="navbutton" style={{ display: "flex", justifyContent: 'space-evenly' }}>
        <Link to='/portfolio/concrete-hot-tub' style={styles.link}>
          <Button 
            startIcon={<ArrowBackIosIcon/>}
            style={styles.button}>Prev Project</Button>
        </Link>
        <Link to='/' style={styles.link}>
          <Button style={styles.button}>Back to portfolio</Button>
        </Link>
        <Link to='/portfolio/parametric-vase' style={styles.link}>
          <Button
            endIcon={<ArrowForwardIosIcon/>}
            style={styles.button}>Next Project</Button>
        </Link>
      </div>
    </div>
  )
}

const styles = {
  container: {
    marginTop: 25,
    color: '#fff',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    color: '#fff'
  }, 
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '25px'
  }
}

export default FacadeNumbers

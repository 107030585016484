import React from 'react'
import { Typography, Button } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";

const EnergyNaturalShader = () => {
  AOS.init({
    duration : 1500
  })

  return (
    <div style={styles.container} data-aos="fade-in">
      <Typography align='center' variant='h3' style={{ textTransform: 'uppercase' }}>Energy Neutral Shader</Typography>

      <div style={{ width: '50%', margin: '0 auto' }}>
        <p style={{ textAlign: 'left', fontWeight: 100 }}>In April 2019 I got contacted by a future homeowner who was in the process of building an energy-neutral house. On the south side of the first floor, a small patio was planned to take place. </p>
        <p style={{ textAlign: 'left', fontWeight: 100  }}>The client wanted a shader that is designed in such way that it lets the most sunlight during winter and least during summer. With the help of the Ladybug tools and Anemone for Grasshopper, I ran analysis of the Sunlight hours and was able to suggest an optimal angle for the pergola slats.</p>

        <p style={{ textAlign: 'left', fontWeight: 100, fontSize: 12 }}>To stay on the same slide please hover the mouse over it.</p>
      </div>

      <div style={styles.linkContainer}>
        <div style={{ width: '50%', margin: ' 0 auto'}}>
          <Carousel autoPlay stopOnHover infiniteLoop showIndicators={false} interval={10000}>
            <div>
              <img src={require('../../../assets/energyShader/shader1.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/energyShader/shader2.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/energyShader/shader3.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/energyShader/shader4.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/energyShader/shader5.png')} alt='pic' />
            </div>
            <div>
              <video loop autoPlay width='100%' style={{ marginTop: 85 }}>
                <source src={require('../../../assets/energyShader/video.mp4')} type='video/mp4' />
              </video>
            </div>
            <div>
              <img src={require('../../../assets/energyShader/shader6.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/energyShader/shader7.png')} alt='pic' />
            </div>
          </Carousel>
        </div>
      </div>

      <div style={{ textAlign: "left", width: '50%', margin: '0 auto', marginBottom: 35 }}>
        <p style={{margin: '0', padddin: '0' ,textAlign: "left", fontWeight: 100}}>Year: 2019</p> 
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Company: Private client</p>
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Software: Revit+ Rhino3D + Grasshopper + Ladybug tools + Galapagos</p>
      </div>

      <div className="navbutton" style={{ display: "flex", justifyContent: 'space-evenly' }}>
        <Link to='/portfolio/parametric-vase' style={styles.link}>
          <Button 
            startIcon={<ArrowBackIosIcon/>}
            style={styles.button}>Prev Project</Button>
        </Link>
        <Link to='/' style={styles.link}>
          <Button style={styles.button}>Back to portfolio</Button>
        </Link>
        <Link to='/portfolio/online-slicer' style={styles.link}>
          <Button
            endIcon={<ArrowForwardIosIcon/>}
            style={styles.button}>Next Project</Button>
        </Link>
      </div>
    </div>
  )
}

const styles = {
  container: {
    marginTop: 25,
    color: '#fff',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    color: '#fff'
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '25px'
  }
}

export default EnergyNaturalShader

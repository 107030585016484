import React from 'react'
import { Typography, Button } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";


const GeraniumCufflinks = () => {
  AOS.init({
    duration : 1500
  })

  return (
    <div style={styles.container} data-aos="fade-in">
      <Typography align='center' variant='h3' style={{ textTransform: 'uppercase' }}>Geranium Cufflinks</Typography>

      <div style={{ width: '50%', margin: '0 auto' }}>
        <p style={{ textAlign: 'left', fontWeight: 100 }}>In the Dutch language, when a person retires, it is called “Hij gaat achter de geranium zitten“ which literally translated means “He goes to sit behind the geranium flowers“.</p>
        <p style={{ textAlign: 'left', fontWeight: 100  }}>This became the theme of the farewell presents to the academy director on his last day of work. Our research group wanted to give him a set of unique 3D metal printed cufflinks with the geranium flower as decoration. I had to design them in a way that looked organic yet printable.</p>

        <p style={{ textAlign: 'left', fontWeight: 100, fontSize: 12 }}>To stay on the same slide please hover the mouse over it.</p>
      </div>

      <div style={styles.linkContainer}>
        <div style={{ width: '50%', margin: '0 auto'}}>
          <Carousel autoPlay stopOnHover infiniteLoop showIndicators={false} interval={10000}>
            <div>
              <img src={require('../../../assets/cufflinks/cufflinkPresentation1.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/cufflinks/cufflinkPresentation2.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/cufflinks/cufflinkPresentation3.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/cufflinks/cufflinkPresentation4.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/cufflinks/cufflinkPresentation5.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/cufflinks/cufflinkPresentation6.png')} alt='pic' />
            </div>
          </Carousel>
        </div>
      </div>

      <div style={{ textAlign: "left", width: '50%', margin: '0 auto', marginBottom: 35 }}>
        <p style={{margin: '0', padddin: '0' ,textAlign: "left", fontWeight: 100}}>Year: 2018</p> 
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Company: Saxion</p>
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Software: Rhino3D + Fusion 360</p>
      </div>

      <div className="navbutton" style={{ display: "flex", justifyContent: 'space-evenly' }}>
        <Link to='/portfolio/green-dome' style={styles.link}>
          <Button 
            startIcon={<ArrowBackIosIcon/>}
            style={styles.button}>Prev Project</Button>
        </Link>
        <Link to='/' style={styles.link}>
          <Button style={styles.button}>Back to portfolio</Button>
        </Link>
        <Link to='/portfolio/concrete-hot-tub' style={styles.link}>
          <Button
            endIcon={<ArrowForwardIosIcon/>}
            style={styles.button}>Next Project</Button>
        </Link>
      </div>
    </div>
  )
}

const styles = {
  container: {
    marginTop: 25,
    color: '#fff',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    color: '#fff'
  }, 
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '25px'
  }
}

export default GeraniumCufflinks

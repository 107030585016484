import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import portfolioData from './data/PortfolioData';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import AOS from "aos";
import "aos/dist/aos.css";
import './Portfolio.css'
import { Link } from 'react-router-dom';

const styles = {
  root: {
    flexGrow: 1,
    padding: 25
  },
  image: {
    width: "100%"
  },
  spacer: {
    width: "100%",
    height: 20
  },
  link: {
    textDecoration: 'none'
  }
};

const Portfolio = props => {
  const { classes } = props

  AOS.init({
    duration : 1500
  })

  return (
    <div className={classes.root}>
      <div className={classes.spacer}></div>
      <Grid container spacing={3} style={{ marginTop: 10 }}>
        <Grid data-aos="zoom-in" item lg={3} md={4} sm={6} xs={12}>
          <Link to="/portfolio/green-facade" className={classes.link}>
            <Typography className="title" variant="h6">
              {portfolioData[0].title}
            </Typography>
            <img
              src={portfolioData[0].img}
              alt={portfolioData[0].title}
              title={portfolioData[0].title}
              className={classes.image}
            />
          </Link>
        </Grid>

        <Grid data-aos="zoom-in" item lg={3} md={4} sm={6} xs={12}>
          <Link to="/portfolio/dam" className={classes.link}>
            <Typography className="title" variant="h6">
              {portfolioData[11].title}
            </Typography>
            <img
              src={portfolioData[11].img}
              alt={portfolioData[11].title}
              title={portfolioData[11].title}
              className={classes.image}
            />
          </Link>
        </Grid>

        <Grid data-aos="zoom-in" item lg={3} md={4} sm={6} xs={12}>
          <Link to="/portfolio/sphere" className={classes.link}>
            <Typography className="title" variant="h6">
              {portfolioData[12].title}
            </Typography>
            <img
              src={portfolioData[12].img}
              alt={portfolioData[12].title}
              title={portfolioData[12].title}
              className={classes.image}
            />
          </Link>
        </Grid>

        <Grid data-aos="zoom-in" item lg={3} md={4} sm={6} xs={12}>
          <Link to="/portfolio/green-dome" className={classes.link}>
            <Typography className="title" variant="h6">
              {portfolioData[3].title}
            </Typography>
            <img
              src={portfolioData[3].img}
              alt={portfolioData[3].title}
              title={portfolioData[3].title}
              className={classes.image}
            />
          </Link>
        </Grid>

        <Grid data-aos="zoom-in" item lg={3} md={4} sm={6} xs={12}>
          <Link to="/portfolio/geranium-cufflinks" className={classes.link}>
            <Typography className="title" variant="h6">
              {portfolioData[4].title}
            </Typography>
            <img
              src={portfolioData[4].img}
              alt={portfolioData[4].title}
              title={portfolioData[4].title}
              className={classes.image}
            />
          </Link>
        </Grid>

        <Grid data-aos="zoom-in" item lg={3} md={4} sm={6} xs={12}>
          <Link to="/portfolio/concrete-hot-tub" className={classes.link}>
            <Typography className="title" variant="h6">
              {portfolioData[8].title}
            </Typography>
            <img
              src={portfolioData[8].img}
              alt={portfolioData[8].title}
              title={portfolioData[8].title}
              className={classes.image}
            />
          </Link>
        </Grid>

        <Grid data-aos="zoom-in" item lg={3} md={4} sm={6} xs={12}>
          <Link to="/portfolio/facade-elements" className={classes.link}>
            <Typography className="title" variant="h6">
              {portfolioData[6].title}
            </Typography>
            <img
              src={portfolioData[6].img}
              alt={portfolioData[6].title}
              title={portfolioData[6].title}
              className={classes.image}
            />
          </Link>
        </Grid>

        <Grid data-aos="zoom-in" item lg={3} md={4} sm={6} xs={12}>
          <Link to="/portfolio/parametric-vase" className={classes.link}>
            <Typography className="title" variant="h6">
              {portfolioData[7].title}
            </Typography>
            <img
              src={portfolioData[7].img}
              alt={portfolioData[7].title}
              title={portfolioData[7].title}
              className={classes.image}
            />
          </Link>
        </Grid>

        <Grid data-aos="zoom-in" item lg={3} md={4} sm={6} xs={12}>
          <Link to="/portfolio/energy-neutral-shader" className={classes.link}>
            <Typography className="title" variant="h6">
              {portfolioData[5].title}
            </Typography>
            <img
              src={portfolioData[5].img}
              alt={portfolioData[5].title}
              title={portfolioData[5].title}
              className={classes.image}
            />
          </Link>
        </Grid>

        <Grid data-aos="zoom-in" item lg={3} md={4} sm={6} xs={12}>
          <Link to="/portfolio/online-slicer" className={classes.link}>
            <Typography className="title" variant="h6">
              {portfolioData[9].title}
            </Typography>
            <img
              src={portfolioData[9].img}
              alt={portfolioData[9].title}
              title={portfolioData[9].title}
              className={classes.image}
            />
          </Link>
        </Grid>

        <Grid data-aos="zoom-in" item lg={3} md={4} sm={6} xs={12}>
          <Link to="/portfolio/welding-pushers" className={classes.link}>
            <Typography className="title" variant="h6">
              {portfolioData[1].title}
            </Typography>
            <img
              src={portfolioData[1].img}
              alt={portfolioData[1].title}
              title={portfolioData[1].title}
              className={classes.image}
            />
          </Link>
        </Grid>

        <Grid data-aos="zoom-in" item lg={3} md={4} sm={6} xs={12}>
          <Link to="/portfolio/tracheal-stoma-stud" className={classes.link}>
            <Typography className="title" variant="h6">
              {portfolioData[2].title}
            </Typography>
            <img
              src={portfolioData[2].img}
              alt={portfolioData[2].title}
              title={portfolioData[2].title}
              className={classes.image}
            />
          </Link>
        </Grid>

        <Grid data-aos="zoom-in" item lg={3} md={4} sm={6} xs={12}>
          <Link to="/portfolio/parametric-art" className={classes.link}>
            <Typography className="title" variant="h6">
              {portfolioData[13].title}
            </Typography>
            <img
              src={portfolioData[13].img}
              alt={portfolioData[13].title}
              title={portfolioData[13].title}
              className={classes.image}
            />
          </Link>
        </Grid>

        <Grid data-aos="zoom-in" item lg={3} md={4} sm={6} xs={12}>
          <Link to="/portfolio/miscellaneous" className={classes.link}>
            <Typography className="title" variant="h6">
              {portfolioData[10].title}
            </Typography>
            <img
              src={portfolioData[10].img}
              alt={portfolioData[10].title}
              title={portfolioData[10].title}
              className={classes.image}
            />
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Portfolio);

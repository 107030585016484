import React from "react";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBarCollapse from "./toggleMenu/AppBarCollapse";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";


const styles = theme => ({
    root: {
    flexGrow: 1
  },
  
  title: {
    marginLeft: 10,
    color: '#fff',
    textDecoration: 'none',
    paddingLeft: 25
  },

  titleLink: {
    textDecoration: 'none',
    color: '#fff'
  },

  appBar: {
    background: '#151515'
  },

  toolBar: {
    justifyContent: 'space-around',
  },

  link: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    textDecoration: 'none',
    color: '#fff',
  },

  indicatorColor: {
    backgroundColor: '#fff',
  }
});

function NavBar(props) {
  const { classes } = props

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Link to='/' className={classes.title}>
            <Typography variant="h5">
                IMAN HADZHIVALCHEVA
            </Typography>
          </Link>
          <Tabs
            classes={{ indicator: classes.indicatorColor }}
            value={value}
            onChange={handleChange}
            centered
          >
            <Tab label="Portfolio" to="/" component={Link} className={classes.link}/>
            <Tab label="Resume" to="/resume" component={Link} className={classes.link} />
          </Tabs>
          <AppBarCollapse />
        </Toolbar>
      </AppBar>
    </div>
  );
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NavBar)
import React, { Component } from "react";
import Navbar from "./components/navbar/Navbar";
import Routes from "./components/Routes";

class App extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <Routes />
      </div>
    );
  }
}

export default App

import React from 'react'
import { Link } from 'react-router-dom'
import { MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ButtonAppBarCollapse from './ButtonAppBarCollapse'

const styles = {
  root: {
    position: 'absolute',
    right: 0
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    marginRight: '25px'
  }
}

const AppBarCollapse = props => {
  const { classes } = props

  return (
    <div className={classes.root}>
      <ButtonAppBarCollapse>
        <MenuItem><Link to='/' className={classes.link}>Portfolio</Link></MenuItem>
        <MenuItem><Link to='/resume' className={classes.link}>Resume</Link></MenuItem>
        <MenuItem><Link to='/contact' className={classes.link}>Contact</Link></MenuItem>
      </ButtonAppBarCollapse>
    </div>
  )
}

export default withStyles(styles)(AppBarCollapse)

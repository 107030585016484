import React from 'react'
import { Typography, Button } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";

const ConcreteHotTub = () => {
  AOS.init({
    duration : 1500
  })

  return (
    <div style={styles.container} data-aos="fade-in">
        <Typography align='center' variant='h3' style={{ textTransform: 'uppercase' }}>Concrete Hot Tub</Typography>

        <div style={{ width: '50%', margin: '0 auto' }}>
          <p style={{ textAlign: 'left', fontWeight: 100 }}>Developing creative and functional applications for 3D concrete print is still a challenge. It is up to designers, architect, artists and engineers to expand the boundaries of what is possible today.</p>
          <p style={{ textAlign: 'left', fontWeight: 100  }}>With great enthusiasm I entered the design challenge by KempC on Applications of 3D concrete printing with a hot tub heated by fire providing a cozy retreat.</p>

          <p style={{ textAlign: 'left', fontWeight: 100, fontSize: 12 }}>To stay on the same slide please hover the mouse over it.</p>
        </div>
      
      <div style={styles.linkContainer}>
        <div style={{ width: '50%', margin: ' 0 auto'}}>
          <Carousel autoPlay stopOnHover infiniteLoop showIndicators={false} interval={10000}>
            <div>
              <img src={require('../../../assets/concreteHotTub/competition1.png')} alt='pic' />
            </div>
            <div>
              <video loop autoPlay width='100%' style={{ marginTop: 40 }}>
                <source src={require('../../../assets/concreteHotTub/video1.mp4')} type='video/mp4' />
              </video>
            </div>
            <div>
              <img src={require('../../../assets/concreteHotTub/competition2.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/concreteHotTub/competition3.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/concreteHotTub/competition4.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/concreteHotTub/competition5.png')} alt='pic' />
            </div>
            <div>
              <img src={require('../../../assets/concreteHotTub/competition6.png')} alt='pic' />
            </div>
            <div>
              <video loop autoPlay width='100%' style={{ marginTop: 90 }}>
                <source src={require('../../../assets/concreteHotTub/video2.mp4')} type='video/mp4' />
              </video>
            </div>
          </Carousel>
        </div>
      </div>

      <div style={{ textAlign: "left", width: '50%', margin: '0 auto', marginBottom: 35 }}>
        <p style={{margin: '0', padddin: '0' ,textAlign: "left", fontWeight: 100}}>Year: 2020</p> 
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Company: KempC</p>
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Software: Rhino + Grasshopper+ Fusion 360</p>
      </div>

      <div className="navbutton" style={{ display: "flex", justifyContent: 'space-evenly' }}>
        <Link to='/portfolio/geranium-cufflinks' style={styles.link}>
          <Button 
            startIcon={<ArrowBackIosIcon/>}
            style={styles.button}>Prev Project</Button>
        </Link>
        <Link to='/' style={styles.link}>
          <Button style={styles.button}>Back to portfolio</Button>
        </Link>
        <Link to='/portfolio/facade-elements' style={styles.link}>
          <Button
            endIcon={<ArrowForwardIosIcon/>}
            style={styles.button}>Next Project</Button>
        </Link>
      </div>
    </div>
  )
}

const styles = {
  container: {
    marginTop: 25, 
    color: '#fff',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    color: '#fff'
  }, 
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '25px'
  }
}

export default ConcreteHotTub

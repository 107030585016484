import React from 'react'
import { Typography, Button } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom'
import ReactPlayer from "react-player"

const LineSlicer = () => {
  return (
    <div style={styles.container} data-aos="fade-in">
      <Typography
        align="center"
        variant="h3"
        style={{ textTransform: "uppercase" }}
      >
        Online Slicer
      </Typography>

      <div style={{ width: "50%", margin: "0 auto" }}>
        <p style={{ textAlign: "left", fontWeight: 100 }}>
          Large scale 3D printing (for example concrete, ceramic and plastic) is
          yet lacking a free dedicated 3D slicing solution.
        </p>
        <p style={{ textAlign: "left", fontWeight: 100 }}>
          Most companies with business in this area have developed their own
          software or Grasshopper scripts to prepare the printing path of their
          designs. This, of course, requires time and money.
        </p>
        <p style={{ textAlign: "left", fontWeight: 100 }}>
          Together with Saxion, Vertico and White Lioness we aim at bringing
          on-line 3D slicing solution to help academia and start-ups begin with
          large scale 3D printing by saving them time, effort and capital
          otherwise spent in developing own slicing solutions.
        </p>
      </div>

      <div style={{ width: "45%", margin: "0 auto", marginBottom: "20px" }}>
        <ReactPlayer
          width="100%"
          height="50vh"
          url="https://www.youtube.com/watch?v=cceMeyzh-hQ"
        />
      </div>

      <div
        style={{
          textAlign: "left",
          width: "50%",
          margin: "0 auto",
          marginBottom: 35,
        }}
      >
        <p
          style={{
            margin: "0",
            padddin: "0",
            textAlign: "left",
            fontWeight: 100,
          }}
        >
          Year: 2020
        </p>
        <p
          style={{
            margin: "0",
            padddin: "0",
            textAlign: "left",
            fontWeight: 100,
          }}
        >
          Company: Saxion, Vertico, White Lioness
        </p>
        <p
          style={{
            margin: "0",
            padddin: "0",
            textAlign: "left",
            fontWeight: 100,
          }}
        >
          Software: Rhino + Grasshopper
        </p>
      </div>

      <div
        className="navbutton"
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <Link to="/portfolio/energy-neutral-shader" style={styles.link}>
          <Button startIcon={<ArrowBackIosIcon />} style={styles.button}>
            Prev Project
          </Button>
        </Link>
        <Link to="/" style={styles.link}>
          <Button style={styles.button}>Back to portfolio</Button>
        </Link>
        <Link to="/portfolio/welding-pushers" style={styles.link}>
          <Button endIcon={<ArrowForwardIosIcon />} style={styles.button}>
            Next Project
          </Button>
        </Link>
      </div>
    </div>
  );
}

const styles = {
  container: {
    marginTop: 25,
    color: '#fff',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    color: '#fff'
  }, 
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '300px'
  }
}

export default LineSlicer

import React from 'react'
import { Typography, Button } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom'
import ReactPlayer from "react-player"

const GreenFacade = () => {
  return (
    <div style={styles.container} data-aos="fade-in">
      <Typography align='center' variant='h3' style={{ textTransform: 'uppercase' }}>Green Facade</Typography>

      <div style={{ width: '50%', margin: '0 auto' }}>
        <p style={{ textAlign: 'left', fontWeight: 100 }}>During my graduation internship at Saxion research group Industrial design, I worked on research and development of creative applications for 3D concrete printing.</p>
        <p style={{ textAlign: 'left', fontWeight: 100  }}>I have always been fascinated by green walls and wondered why aren’t there more of them in new buildings.</p>
        <p style={{ textAlign: 'left', fontWeight: 100  }}>Using the form freedom and function integration capabilities of 3D concrete printing, I saw as a design challenge to bring the plant life internal to the 3D printed concrete structures.</p>
      </div>

      <div style={{ width: '45%', margin: '0 auto', marginBottom: '20px' }}>
        <ReactPlayer
          width='100%'
          height='50vh'
          url="https://www.youtube.com/watch?v=tEbtCkD5j_s"
        />
      </div>

      <div style={{ textAlign: "left", width: '50%', margin: '0 auto', marginBottom: 35 }}>
        <p style={{margin: '0', padddin: '0' ,textAlign: "left", fontWeight: 100}}>Year: 2017 – 2019</p> 
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Company: Trebbe, De Witte van der Heijden Architecten, Vertico, Saxion</p>
        <p style={{margin: '0', padddin: '0', textAlign: "left", fontWeight: 100}}>Design software: Rhino + Grasshopper + Fusin 360</p>
      </div>

      <div className="navbutton" style={{ display: "flex", justifyContent: 'space-evenly' }}>
      <Link to='/' style={styles.link}>
          <Button 
            startIcon={<ArrowBackIosIcon/>}
            style={styles.button}>Back to portfolio</Button>
        </Link>
        <Link to='/portfolio/dam' style={styles.link}>
          <Button
            endIcon={<ArrowForwardIosIcon/>}
            style={styles.button}>Next Project</Button>
        </Link>
      </div>
    </div>
  )
}

const styles = {
  container: {
    marginTop: 25,
    color: '#fff',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    color: '#fff'
  }, 
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '25px'
  }
}

export default GreenFacade

import { Button, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

const Sphere = () => {
  return (
    <div style={styles.container} data-aos="fade-in">
      <Typography
        align="center"
        variant="h3"
        style={{ textTransform: "uppercase" }}
      >
        Sphere
      </Typography>

      <div style={{ width: "50%", margin: "0 auto" }}>
        <p style={{ textAlign: "left", fontWeight: 100 }}>

        </p>
        <p style={{ textAlign: "left", fontWeight: 100 }}>

        </p>
      </div>

      <div style={{ width: "45%", margin: "0 auto", marginBottom: "20px" }}>
        <ReactPlayer
          width="100%"
          height="50vh"
          url="https://www.youtube.com/watch?v=FMMNLDrvql8"
        />
      </div>

      <div
        style={{
          textAlign: "left",
          width: "50%",
          margin: "0 auto",
          marginBottom: 35,
        }}
      >
        <p
          style={{
            margin: "0",
            padddin: "0",
            textAlign: "left",
            fontWeight: 100,
          }}
        >
          Year: 2022
        </p>
        <p
          style={{
            margin: "0",
            padddin: "0",
            textAlign: "left",
            fontWeight: 100,
          }}
        >
          Company: Saxion, Vertico Creastone
        </p>
        <p
          style={{
            margin: "0",
            padddin: "0",
            textAlign: "left",
            fontWeight: 100,
          }}
        >
          Design software: Rhino + Grasshopper + Robots + Robot Studio Hardware ABB
        </p>
      </div>

      <div
        className="navbutton"
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <Link to="/portfolio/dam" style={styles.link}>
          <Button startIcon={<ArrowBackIosIcon />} style={styles.button}>
            Previous Project
          </Button>
        </Link>
        <Link to="/" style={styles.link}>
          <Button style={styles.button}>Back to portfolio</Button>
        </Link>
        <Link to="/portfolio/green-dome" style={styles.link}>
          <Button endIcon={<ArrowForwardIosIcon />} style={styles.button}>
            Next Project
          </Button>
        </Link>
      </div>
    </div>
  );
};

const styles = {
  container: {
    marginTop: 25,
    color: "#fff",
    textAlign: "center",
  },
  link: {
    textDecoration: "none",
  },
  button: {
    color: "#fff",
  },
  linkContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
};

export default Sphere;

import React, { Component } from 'react'
import { Route, Switch } from 'react-router'
import Resume from './pages/Resume'
import Portfolio from './pages/Portfolio'
import GreenFacade from './pages/portfolio/GreenFacade'
import WeldingPushers from './pages/portfolio/WeldingPushers'
import TrachealCannula from './pages/portfolio/TrachealCannula'
import GreenDome from './pages/portfolio/GreenDome'
import GeraniumCufflinks from './pages/portfolio/GeraniumCufflinks'
import EnergyNaturalShader from './pages/portfolio/EnergyNaturalShader'
import FacadeNumbers from './pages/portfolio/FacadeNumbers'
import ParametricVase from './pages/portfolio/ParametricVase'
import ConcreteHotTub from './pages/portfolio/ConcreteHotTub'
import LineSlicer from './pages/portfolio/OnlineSlicer'
import Miscellaneous from './pages/portfolio/Miscellaneous'
import Dam from './pages/portfolio/Dam'
import Sphere from './pages/portfolio/Sphere'
import ParametricArt from "./pages/portfolio/ParametricArt";

export default class Routes extends Component {
  render() {
    return (
      <div>
          <Route path='/resume' component={Resume} />
        <Switch>
          <Route exact path='/' component={Portfolio} />
          <Route path='/portfolio/green-facade' component={GreenFacade} />
          <Route path='/portfolio/welding-pushers' component={WeldingPushers} />
          <Route path='/portfolio/tracheal-stoma-stud' component={TrachealCannula} />
          <Route path='/portfolio/green-dome' component={GreenDome} />
          <Route path='/portfolio/geranium-cufflinks' component={GeraniumCufflinks} />
          <Route path='/portfolio/energy-neutral-shader' component={EnergyNaturalShader} />
          <Route path='/portfolio/facade-elements' component={FacadeNumbers} />
          <Route path='/portfolio/parametric-vase' component={ParametricVase} />
          <Route path='/portfolio/concrete-hot-tub' component={ConcreteHotTub} />
          <Route path='/portfolio/online-slicer' component={LineSlicer} />
          <Route path='/portfolio/miscellaneous' component={Miscellaneous} />
          <Route path='/portfolio/dam' component={Dam} />
          <Route path='/portfolio/sphere' component={Sphere} />
          <Route path='/portfolio/parametric-art' component={ParametricArt} />
        </Switch>
      </div>
    )
  }
}

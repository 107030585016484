import React from 'react'
import { Typography, Button } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";

const WeldingPushers = () => {
  AOS.init({
    duration : 1500
  })

  return (
    <div style={styles.container} data-aos="fade-in">
      <Typography
        align="center"
        variant="h3"
        style={{ textTransform: "uppercase" }}
      >
        Welding Pushers
      </Typography>

      <div style={{ width: "50%", margin: "0 auto" }}>
        <p style={{ textAlign: "left", fontWeight: 100 }}>
          A welding jig is a large brace that keeps a welding project stable in
          the face of pressure, heat, motion, and force. To prevent bending of
          the metal, additional pushers are installed to press onto specific
          points.
        </p>
        <p style={{ textAlign: "left", fontWeight: 100 }}>
          This is done with the use of standard pushers attached to the lid of
          the jig. The space available is usually very limited and using only
          standard pushers often proves impossible. In these cases custom
          pushers need to be designed and produced.
        </p>
        <p style={{ textAlign: "left", fontWeight: 100 }}>
          This project aimed at integrating 3D metal printing for these
          situations and applying of parametric modeling principles to the
          generation of the pusher’s geometry to reduce the time needed to make
          a custom pushers.{" "}
        </p>

        <p style={{ textAlign: "left", fontWeight: 100, fontSize: 12 }}>
          To stay on the same slide please hover the mouse over it.
        </p>
      </div>

      <div style={styles.linkContainer}>
        <div style={{ width: "50%", margin: "0 auto" }}>
          <Carousel
            autoPlay
            stopOnHover
            infiniteLoop
            showIndicators={false}
            interval={10000}
          >
            <div>
              <img
                src={require("../../../assets/weldingPushers/3DPushers1.png")}
                alt="pic"
              />
            </div>
            <div>
              <img
                src={require("../../../assets/weldingPushers/3DPushers2.png")}
                alt="pic"
              />
            </div>
            <div>
              <img
                src={require("../../../assets/weldingPushers/3DPushers3.png")}
                alt="pic"
              />
            </div>
            <div>
              <img
                src={require("../../../assets/weldingPushers/3DPushers4.png")}
                alt="pic"
              />
            </div>
            <div>
              <img
                src={require("../../../assets/weldingPushers/3DPushers5.png")}
                alt="pic"
              />
            </div>
            <div>
              <img
                src={require("../../../assets/weldingPushers/3DPushers6.png")}
                alt="pic"
              />
            </div>
            <div>
              <img
                src={require("../../../assets/weldingPushers/3DPushers7.png")}
                alt="pic"
              />
            </div>
          </Carousel>
        </div>
      </div>

      <div
        style={{
          textAlign: "left",
          width: "50%",
          margin: "0 auto",
          marginBottom: 35,
        }}
      >
        <p
          style={{
            margin: "0",
            padddin: "0",
            textAlign: "left",
            fontWeight: 100,
          }}
        >
          Year: 2019{" "}
        </p>
        <p
          style={{
            margin: "0",
            padddin: "0",
            textAlign: "left",
            fontWeight: 100,
          }}
        >
          Companies: Saxion, AWL, MechDes Engineering
        </p>
        <p
          style={{
            margin: "0",
            padddin: "0",
            textAlign: "left",
            fontWeight: 100,
          }}
        >
          Collaboration with: Fabian Jacobs
        </p>
        <p
          style={{
            margin: "0",
            padddin: "0",
            textAlign: "left",
            fontWeight: 100,
          }}
        >
          Design software: Rhino3D + Grasshopper + Solidworks
        </p>
      </div>

      <div
        className="navbutton"
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <Link to="/portfolio/online-slicer" style={styles.link}>
          <Button startIcon={<ArrowBackIosIcon />} style={styles.button}>
            Previous Project
          </Button>
        </Link>
        <Link to="/" style={styles.link}>
          <Button style={styles.button}>Back to portfolio</Button>
        </Link>
        <Link to="/portfolio/tracheal-stoma-stud" style={styles.link}>
          <Button endIcon={<ArrowForwardIosIcon />} style={styles.button}>
            Next Project
          </Button>
        </Link>
      </div>
    </div>
  );
}

const styles = {
  container: {
    marginTop: 25,
    color: '#fff',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    color: '#fff'
  }, 
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '25px'
  }
}

export default WeldingPushers
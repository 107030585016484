import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AOS from "aos";
import "aos/dist/aos.css";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: "50%",
    margin: "50px auto",
  },
  typography: {
    textTransform: "uppercase",
    letterSpacing: 2,
    color: "#fff",
    textAlign: "left",
    marginTop: 25,
  },
  paragraphs: {
    color: "#fff",
    textAlign: "left",
    letterSpacing: 1,
    fontWeight: 100,
    margin: 5,
    padding: 0,
  },
}));

const Resume = () => {
  const classes = useStyles();

  AOS.init({
    duration: 1000,
  });

  return (
    <div className={classes.root} data-aos="fade-up">
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ borderBottom: "1px solid #ddd", paddingBottom: 5 }}
        >
          <Typography className={classes.typography} variant="h4">
            Summary
          </Typography>
          <p className={classes.paragraphs}>
            I am a designer by heart and a researcher by mind with a problem
            solving attitude. I am continuously fascinated by the process of
            adoption and adaptation to new technologies. I am passionate about
            translating the complex into simple, the chaos into flow and the
            fuzzy ideas into real solutions.
          </p>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ borderBottom: "1px solid #ddd", paddingBottom: 5 }}
        >
          <Typography className={classes.typography} variant="h4">
            Work experience
          </Typography>

          <Typography
            style={{ marginTop: "20px" }}
            variant="h6"
            className={classes.typography}
          >
            Researcher/Designer
          </Typography>
          <div>
            <p className={classes.paragraphs}>
              Saxion Lectoraat Industrial Design
            </p>
            <p className={classes.paragraphs}>Aug 2017 - Present</p>
            <p className={classes.paragraphs}>Enschede, Netherlands</p>
            <p className={classes.paragraphs}>
              Applied design research and project-based topic unpacking within
              the research lines of Saxion Research group Industrial Design:
              User-centered design, new materials and technologies and
              Sustainability. Parametric design and methodology of 3D modeling
              for 3D printing. Projects also related to implementation of
              parametric modeling in product design, student guidance during
              Smart Solutions Semester, internship and graduation thesis writing
              Bachelor degree.
            </p>
          </div>

          <Typography
            style={{ marginTop: "20px" }}
            variant="h6"
            className={classes.typography}
          >
            Graduation intern with topic “Application of 3D concrete printing”
          </Typography>
          <div>
            <p className={classes.paragraphs}>
              Saxion Lectoraat Industrial Design
            </p>
            <p className={classes.paragraphs}>Feb 2017 - July 2017</p>
            <p className={classes.paragraphs}>Enschede, Netherlands</p>
            <p className={classes.paragraphs}>
              Research on state of the art, stakeholders, tendencies and
              critical analysis of strengths and weaknesses of the technology.
              Research into creative applications of 3D concrete printing,
              conceptualizing and executing of a fully 3D printed structure as
              well as developing printing strategy. Building of own custom
              slicing script.
            </p>
          </div>

          <Typography
            style={{ marginTop: "20px" }}
            variant="h6"
            className={classes.typography}
          >
            Intern Green Media Advertising
          </Typography>
          <div>
            <p className={classes.paragraphs}>GreenGraffiti®</p>
            <p className={classes.paragraphs}>Feb 2015 – July 2015</p>
            <p className={classes.paragraphs}>Amsterdam, Netherlands</p>
            <p className={classes.paragraphs}>
              Research on impact of printed advertisement media. Design and
              execution of multiple advertising campaigns using reverse
              graffiti, moss signage and sand bossing.
            </p>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ borderBottom: "1px solid #ddd", paddingBottom: 5 }}
        >
          <Typography className={classes.typography} variant="h4">
            Education
          </Typography>

          <Typography
            style={{ marginTop: "20px" }}
            variant="h6"
            className={classes.typography}
          >
            MSc Computational Design and Digital Fabrication (CDDF)
          </Typography>
          <div>
            <p className={classes.paragraphs}>University of Nicosia</p>
            <p className={classes.paragraphs}>Sept 2020 - Feb 2022</p>
            <p className={classes.paragraphs}>Nicosia, Cyprus</p>
            <p className={classes.paragraphs}>
              Course: Principles of computational design, Practical approaches
              to parametric design and digital fabrication, Advanced modeling
              techniques, Academic research.
            </p>
          </div>

          <Typography
            style={{ marginTop: "20px" }}
            variant="h6"
            className={classes.typography}
          >
            BSc Art and Technology
          </Typography>
          <div>
            <p className={classes.paragraphs}>
              Saxion University of Applied Sciences
            </p>
            <p className={classes.paragraphs}>Aug 2012 - Jun 2017</p>
            <p className={classes.paragraphs}>Enschede, Netherlands</p>
            <p className={classes.paragraphs}>
              Course: Photography, Audio-visual production and editing, Graphic
              design, Interaction design, 3D modeling, Advertisement, Basics of
              electronics, Physical installations.
            </p>
          </div>

          <Typography
            style={{ marginTop: "20px" }}
            variant="h6"
            className={classes.typography}
          >
            Highschool of Mathematics and Natural Sciences “Academic Boyan
            Petkanchin”
          </Typography>
          <div>
            <p className={classes.paragraphs}>2007 - 2011</p>
            <p className={classes.paragraphs}>Haskovo, Bulgaria</p>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ borderBottom: "1px solid #ddd", paddingBottom: 5 }}
        >
          <Typography className={classes.typography} variant="h4">
            Courses
          </Typography>

          <Typography
            style={{ marginTop: "20px" }}
            variant="h6"
            className={classes.typography}
          >
            Algorithmic Design and Techniques
          </Typography>
          <div>
            <p className={classes.paragraphs}>edX & UC SanDiego</p>
            <p className={classes.paragraphs}>Feb 2020 - No expiration date</p>
            <p className={classes.paragraphs}>
              Credential ID: 66eac47a73154ce7972cb4c5dd213ef8
            </p>
          </div>

          <Typography
            style={{ marginTop: "20px" }}
            variant="h6"
            className={classes.typography}
          >
            Thing-Centered Design - A New Approach to Designing with IOT
          </Typography>
          <div>
            <p className={classes.paragraphs}>
              TU Delft IDE Design Master Class for Professionals
            </p>
            <p className={classes.paragraphs}>Oct 2019</p>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ borderBottom: "1px solid #ddd", paddingBottom: 5 }}
        >
          <Typography className={classes.typography} variant="h4">
            Teaching experience and guest lectures
          </Typography>

          <Typography
            style={{ marginTop: "20px" }}
            variant="h6"
            className={classes.typography}
          >
            Computational design within the Minor "Computational Design and
            Digital Making"
          </Typography>
          <div>
            <p className={classes.paragraphs}>
              Teaching: iterative design principles (growth, loops, etc.),
              topology optimization, (mass)customization of products, and design
              for digital fabrication (such as laser cutting, parametric molds,
              writing custom G-code, etc.)
            </p>
            <p className={classes.paragraphs}>Sept 2021 - present</p>
          </div>

          <Typography
            style={{ marginTop: "20px" }}
            variant="h6"
            className={classes.typography}
          >
            Three-part workshop on dynamic surfaces
          </Typography>
          <div>
            <p className={classes.paragraphs}>
              Teaching: Part of minor program “Mastering 3D” in Saxion
              University of Applied Sciences
            </p>
            <p className={classes.paragraphs}>March 2020 - present</p>
          </div>

          <Typography
            style={{ marginTop: "20px" }}
            variant="h6"
            className={classes.typography}
          >
            Guest lecture on Parametric modeling
          </Typography>
          <div>
            <p className={classes.paragraphs}>
              Speaker: Business, Building & Technology (BBT) academy at Saxion
              University of Applied Sciences
            </p>
            <p className={classes.paragraphs}>Mar 2019</p>
          </div>

          <Typography
            style={{ marginTop: "20px" }}
            variant="h6"
            className={classes.typography}
          >
            Assistant at parametric modeling workshop by Rinus Roelofs
          </Typography>
          <div>
            <p className={classes.paragraphs}>Guest: FabLab Enschede</p>
            <p className={classes.paragraphs}>Apr 2019</p>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ borderBottom: "1px solid #ddd", paddingBottom: 5 }}
        >
          <Typography className={classes.typography} variant="h4">
            Competition
          </Typography>

          <Typography
            style={{ marginTop: "20px" }}
            variant="h6"
            className={classes.typography}
          >
            Applications of 3D concrete printing
          </Typography>
          <div>
            <p className={classes.paragraphs}>
              Kamp-C - Innovation in construction
            </p>
            <p className={classes.paragraphs}>Mar 2020 until May 15, 2020</p>
            <p className={classes.paragraphs}>Status: Shortlisted</p>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ borderBottom: "1px solid #ddd", paddingBottom: 5 }}
        >
          <Typography className={classes.typography} variant="h4">
            Skills
          </Typography>

          <div style={{ display: "flex", color: "#fff" }}>
            <div style={{ flex: "1 500px" }}>
              <Typography
                style={{ marginTop: "20px" }}
                variant="h6"
                className={classes.typography}
              >
                Tools
              </Typography>
              <ul style={{ fontWeight: 100 }}>
                <li>Fusion360</li>
                <li>Rhino3D + Grasshopper</li>
                <li>Ladybug Tools</li>
                <li>Robotstudio</li>
                <li>Adobe creative suite</li>
                <li>Wordpress</li>
                <li>Zbrush</li>
                <li>Meshmixer</li>
              </ul>
            </div>
            <div>
              <Typography
                style={{ marginTop: "20px" }}
                variant="h6"
                className={classes.typography}
              >
                Basic Skills
              </Typography>
              <ul style={{ fontWeight: 100 }}>
                <li>Revit</li>
                <li>Solidworks</li>
                <li>RoboDK</li>
                <li>Python</li>
              </ul>
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ borderBottom: "1px solid #ddd", paddingBottom: 5 }}
        >
          <Typography className={classes.typography} variant="h4">
            Volunteer Experience
          </Typography>

          <Typography
            style={{ marginTop: "20px" }}
            variant="h6"
            className={classes.typography}
          >
            EVS Volunteer
          </Typography>
          <div>
            <p className={classes.paragraphs}>Javni Zavod Mladi Zmaji</p>
            <p className={classes.paragraphs}>Jan 2012 – Aug 2012</p>
            <p className={classes.paragraphs}>Ljubljana, Slovenia</p>
          </div>

          <Typography
            style={{ marginTop: "20px" }}
            variant="h6"
            className={classes.typography}
          >
            Volunteer
          </Typography>
          <div>
            <p className={classes.paragraphs}>
              Alliance Fund - Civil Rights and Social Action
            </p>
            <p className={classes.paragraphs}>Mar 2010 – Sep 2011</p>
            <p className={classes.paragraphs}>Haskovo, Bulgaria</p>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ borderBottom: "1px solid #ddd", paddingBottom: 5 }}
        >
          <Typography className={classes.typography} variant="h4">
            Interests
          </Typography>
          <p className={classes.paragraphs}>
            3D printing, Computational design/Parametric modeling, 3D printing
            strategies, Mass customizations practices, Creative advertisement,
            Photography, Storytelling and History.
          </p>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ borderBottom: "1px solid #ddd", paddingBottom: 5 }}
        >
          <Typography className={classes.typography} variant="h4">
            Contact
          </Typography>
          <p className={classes.paragraphs}>
            Email: iman.h92@gmail.com
          </p>
          <p className={classes.paragraphs}>
            Phone: +31 622683833
          </p>
        </Grid>
      </Grid>
    </div>
  );
};
export default Resume;
